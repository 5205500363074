<template>
  <s-dialog
    v-model:visible="visible"
    :type="'W480'"
    :show-close="true"
    @closed="closeHandle"
  >
    <template #title>
      {{ language.SHEIN_KEY_PC_21452 }}
    </template>
    <template #subtitle>
      {{ language.SHEIN_KEY_PC_21453 }}
    </template>
    <div class="content">
      <s-radio-group
        v-model="selectValue"
        class="select-list"
      >
        <div
          v-for="(item, index) in options"
          :key="index"
          class="select-list__item"
        >
          <s-radio
            :label="index + 1"
            :reverse-select="true"
          >
            {{
              item
            }}
          </s-radio>
        </div>
      </s-radio-group>

      <s-button
        class="btn"
        :type="['primary', 'H44PX']"
        :disabled="!selectValue"
        width="240px"
        @click="submit"
      >
        {{ language.SHEIN_KEY_PC_21451 }}
      </s-button>
    </div>
  </s-dialog>
</template>

<script>
import { SMessage } from '@shein-aidc/sui-message'
import schttp from 'public/src/services/schttp'
import mitt from 'mitt'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { userModule } from 'public/src/pages/common/user_module.js'

const defaultConfig = {
  commentId: '',
  skc: '',
  isFree: false,
  callbacks: {
    submit: () => {},
  },
}
export default {
  name: 'CommentReport',
  props: {
    language: {
      type: Object,
      default() {
        return {}
      },
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      config: {},
      visible: false,
      selectValue: '',
    }
  },
  methods: {
    open(config) {
      if (!config.comment_id) {
        console.error('缺少商品评论 comment_id!')
        return
      }
      this.config = Object.assign({}, defaultConfig, config)
      if (!isLogin()) {
        // not login
        this.showLogin({
          cb: () => (this.visible = true),
        })
        return
      }

      this.visible = true
    },
    showLogin({ cb }) {
      SHEIN_LOGIN.show({
        show: true,
        from: 'other',
        reload: true,
        cb: () => {
          userModule.update_top_login()
          cb()
          if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
            window.appEventCenter = window.appEventCenter || mitt()
            window.appEventCenter?.emit?.('detailLoginSucCb')
            this.config.callbacks?.closeModal?.()
          }
        },
      })
    },
    submit() {
      const { selectValue } = this
      this.reportHandle(selectValue)
    },
    closeHandle() {
      this.selectValue = ''
    },
    // 举报回调
    async reportHandle(reason) {
      const { comment_id, skc, isFree, isOutStoreReview } = this.config
      const type = isOutStoreReview ? 'storeComment' : (isFree ? 'free' : 'comment')
      const res = await schttp({
        url: '/api/comment/report/update',
        params: { comment_id, reason, type, skc },
      })
      const message = this.getMessage(+res.code)
      SMessage(message)
      this.visible = false
      // CallBack
      this.config.callbacks.submit({ comment_id, reason })
    },
    // 获取反馈信息
    getMessage(code) {
      switch (code) {
        case 0:
          return { type: 'success', message: this.language.SHEIN_KEY_PC_21454 }
        case 402028:
        case 200745:
          return { type: 'error', message: this.language.SHEIN_KEY_PC_21455 }
        case 200746:
        case 402029:
          return { type: 'error', message: this.language.SHEIN_KEY_PC_22557 }
        default:
          return { type: 'error', message: this.language.SHEIN_KEY_PC_22449 }
      }
    },
  },
}
</script>

<style lang="less">
.content {
  margin-top: 32px;
  .select-list__item {
    display: inline-flex;
    height: 48px;
    width: 100%;
    padding: 0 16px;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    background: #f6f6f6;
    margin-bottom: 8px;
    box-sizing: border-box;
    &:last-child {
      margin-bottom: 0;
    }
    .sui-radio__label {
      font-size: 14px !important; /* stylelint-disable-line declaration-no-important */
      white-space: pre-wrap;
      word-wrap: break-word;
      box-sizing: border-box;
    }
  }
  .btn {
    margin-top: 32px;
  }
}
</style>
