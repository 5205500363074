<template>
  <s-dialog
    v-model:visible="visible"
    :type="'W720'"
    :show-close="true"
    @closed="closeHandle"
  >
    <template #title>
      {{ language.SHEIN_KEY_PC_21452 }}
    </template>
    <template #subtitle>
      {{ language.SHEIN_KEY_PC_21453 }}
    </template>
    <div class="report-dsa-content">
      <div 
        class="productShippingReport__ProhibitedGoods"
      >
        <div class="productShippingReport__subtitle">
          {{ language.SHEIN_KEY_PC_28983 }}
          <span class="productShippingReport__subtitle-required">&nbsp;*</span>
          <span
            v-show="showRadioWarn"
            class="productShippingReport__subtitle-warn"
          >&nbsp;&nbsp;{{ radioErrorText }}</span>
        </div>
        <s-radio-group
          v-model="formData.radio"
          class="select-list"
          @change="handleRadioChange"
        >
          <div
            v-for="item in options"
            :key="item.value"
            class="select-list__item"
          >
            <s-radio
              :label="item.value"
            >
              {{
                item.label
              }}
            </s-radio>
          </div>
        </s-radio-group>


        <div class="productShippingReport__subtitle">
          {{ language.SHEIN_KEY_PC_29004 }}
          <span class="productShippingReport__subtitle-required">&nbsp;*</span>
          <span
            v-show="showReasonWarn"
            class="productShippingReport__subtitle-warn"
          >&nbsp;&nbsp;{{ language.SHEIN_KEY_PC_29006 }}</span>
          <p class="productShippingReport__content-explain">
            {{ language.SHEIN_KEY_PC_32209 }}
          </p>
        </div>
        
        <s-input
          v-model="formData.reason"
          class="productShippingReport__ProhibitedGoods-textarea"
          :placeholder="language.SHEIN_KEY_PC_29005"
          type="textarea"
          :maxlength="2000"
          show-word-limit
          suffix-icon
          @blur="formReasonBlur"
        />


        <p class="productShippingReport__subtitle">
          {{ language.SHEIN_KEY_PC_32210 }}
          <span class="productShippingReport__subtitle-required">&nbsp;*</span>
          <span
            v-show="showEmailWarn"
            class="productShippingReport__subtitle-warn"
          >&nbsp;&nbsp;{{ errorEmailText }}</span>
        </p>
        <EmailRecommendInput
          v-model.trim="formData.email"
          :clearable="true"
          style="margin-bottom: 12px;"
          @blur="handleEmailBlur"
          @focus="handleEmailFocus"
        />
      </div>

      <div
        class="productShippingReport__confirm"
      >
        <div
          class="productShippingReport__confirm-radio"
          @click="clickConfirmRadio"
        >
          <div class="productShippingReport__confirm-checkbox">
            <Icon
              v-if="formData.agree"
              name="sui_icon_select_selected_20px"
              size="20px"
              color="#222"
            />
          </div>
          <div
            class="productShippingReport__confirm-text"
            v-html="policyText"
          >
          </div>
        </div>
        <div
          v-if="showAgreeWarn"
          class="productShippingReport__agree-warn productShippingReport__subtitle-warn" 
        >
          {{ language.SHEIN_KEY_PC_32412 || 'Please agree the statement' }}
        </div>
      </div>

      <div class="button-wrapper">
        <s-button
          class="btn"
          :type="['primary', 'H44PX']"
          width="240px"
          @click="submit"
        >
          {{ language.SHEIN_KEY_PC_21451 }}
        </s-button>
      </div>
    </div>
  </s-dialog>
</template>

<script>
import { SMessage } from '@shein-aidc/sui-message'
import schttp from 'public/src/services/schttp'
import EmailRecommendInput from 'public/src/pages/goods_detail_v2/components/ProductIntroShippingEnter/ReportDialog/ReportDialogDsa/EmailRecommendInput.vue'
import {
  patternEmail,
  template,
} from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

const defaultConfig = {
  commentId: '',
  skc: '',
  isFree: false,
  callbacks: {
    submit: () => {},
  },
}
export default {
  name: 'CommentReportDsa',
  components: {
    EmailRecommendInput,
    Icon
  },
  props: {
    language: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      config: {},
      visible: false,
      formData: {
        radio: '',
        reason: '',
        email: '',
        agree: false
      },
      showRadioWarn: false,
      showAgreeWarn: false,
      showReasonWarn: false,
      showEmailWarn: false,
    }
  },
  computed: {
    radioErrorText() {
      return 'please select the type'
    },
    policyText() {
      return template(`<span class="policy" onclick="REPORT__POLICY__CLICK(event)">${this.language.SHEIN_KEY_PC_32242}</span>`, this.language.SHEIN_KEY_PC_32241)
    },
    errorEmailText() {
      return !this.formData.email.trim() ? this.language.SHEIN_KEY_PC_32211 : this.language.SHEIN_KEY_PC_32212
    },
    options() {
      return [
        {
          label: this.language.SHEIN_KEY_PC_22442,
          value: 19,
        },
        {
          label: this.language.SHEIN_KEY_PC_22444,
          value: 20,
        },
        {
          label: this.language.SHEIN_KEY_PC_22446,
          value: 21,
        },
        {
          label: this.language.SHEIN_KEY_PC_22447,
          value: 22,
        },
        {
          label: this.language.SHEIN_KEY_PC_22443,
          value: 23,
        },
        {
          label: this.language.SHEIN_KEY_PC_22445,
          value: 24,
        }
      ]
    }
  },
  watch: {
    'formData.reason'() {
      if (this.showReasonWarn && this.formData.reason.length >= 30) {
        this.showReasonWarn = false
      }
    },
    'formData.agree'(val) {
      if (val) {
        this.showAgreeWarn = false
      }
    },
    'formData.email'(val) {
      if (val?.trim()) {
        this.showEmailWarn = false
      }
    }
  },
  async created() {
    window.REPORT__POLICY__CLICK = (e) => {
      e.preventDefault()
      e.stopPropagation()
      window.open(`${gbCommonInfo.host}/Privacy-Center-a-282.html`,)
    }

    this.formData.email = this.cacheEmail = await UserInfoManager.get({ key: 'userinfo_email', actionType: 'dsaReport' }) || ''
  },
  methods: {
    open(config) {
      if (!config.comment_id) {
        console.error('缺少商品评论 comment_id!')
        return
      }
      this.config = Object.assign({}, defaultConfig, config)
      this.visible = true
    },
    submit() {
      this.reportHandle()
    },
    closeHandle() {
      // reset
      this.formData = {
        radio: '',
        reason: '',
        email: this.cacheEmail,
        agree: false
      }

      this.showReasonWarn = false
      this.showEmailWarn = false
      this.showRadioWarn = false
      this.showAgreeWarn = false
    },
    // 举报回调
    async reportHandle() {
      if (!this.formData.radio) {
        this.showRadioWarn = true
      }

      if (!this.formData.reason || this.formData.reason.length < 30) {
        this.showReasonWarn = true 
      }

      if (!this.formData.email.trim() || !patternEmail({ email: this.formData.email })) {
        this.showEmailWarn = true
      }

      if (this.showRadioWarn || this.showReasonWarn || this.showEmailWarn) return 

      if (!this.formData.agree) {
        this.showAgreeWarn = true
        return
      }

      const { comment_id, isOutStoreReview, reportMemberId } = this.config

      const data = {
        reportSource: 1, // 评论
        reportEntity: comment_id,
        themesId: this.formData.radio,
        reportDesc: this.formData.reason,
        email: this.formData.email,
        commentSource: isOutStoreReview ? 1 : 0,
        brandId: gbCommonInfo?.WEB_CLIENT === 'shein' ? 7 : 9,
        reportMemberId
      }

      const res = await schttp({
        method: 'POST',
        url: '/api/productInfo/report/add',
        data
      })

      if (+res.code === 0) {
        SMessage(this.language.SHEIN_KEY_PC_21454 )
      } else {
        SMessage('ERROR')
      }
      this.visible = false
      // CallBack
      this.config.callbacks.submit({ comment_id, reason: this.formData.reason })
    },
    clickConfirmRadio() {
      this.formData.agree = !this.formData.agree
    },
    formReasonBlur() {
      this.showReasonWarn = this.formData.reason.length < 30
    },
    handleRadioChange(val) {
      if (val) {
        this.showRadioWarn = false
      }
    },
    handleEmailBlur() {
      if (!this.formData.email?.trim()) {
        this.showEmailWarn = true
      }
    },
    handleEmailFocus() {
      this.showEmailWarn = false
    },
  }
}
</script>

<style lang="less">
.report-dsa-content {
  margin-top: 10px;
  .select-list {
    width: 100%;
    margin-bottom: 24px;
  }
  .select-list__item {
    display: inline-flex;
    height: 48px;
    width: 100%;
    padding: 0 16px;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    background: #f6f6f6;
    margin-bottom: 8px;
    box-sizing: border-box;
    &:last-child {
      margin-bottom: 0;
    }
    .sui-radio__label {
      font-size: 14px !important; /* stylelint-disable-line declaration-no-important */
      white-space: pre-wrap;
      word-wrap: break-word;
      box-sizing: border-box;
    }
  }
  .btn {
    margin-top: 32px;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
  }

  .productShippingReport {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__close {
      position: absolute;
      right: 7px;
      top: 7px;
      cursor: pointer;
    }
    &__title {
      font-size: 20px;
      font-weight: 900;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
      color: @sui_color_gray_dark1;
      margin-bottom: 32px;
    }
    &__subtitle {
      margin-bottom: 16px;
      font-family: Arial;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
    &__subtitle-required {
      color: #C44A01;
    }
    &__subtitle-warn {
      color: #D53333;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
    }

    &__content-explain {
      font-weight: normal;
      font-size: 12px;
      color: #959595;
      margin-top: 4px;
    }

    &__FraudulentSellers {
      width: 100%;
      margin-bottom: 24px;
    }
    &__FraudulentSellers-popContent {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__FraudulentSellers-popContentItem {
      & > p:nth-child(1) {
        font-size: 12px;
        line-height: 14px;
        color: #222222;
        font-weight: 700;
        margin-bottom: 4px;
      }
      & > p:nth-child(2) {
        font-size: 12px;
        line-height: 14px;
        color: #666666;
      }
    }
    &__FraudulentSellers-radios {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__ProhibitedGoods {
      width: 100%;
      margin-top: 20px;
      .sui-textarea__num {
        bottom: 30px;
        right: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: right;
        color: #767676;
      }
    }
    &__ProhibitedGoods-textarea {
      height: 79px;
      min-height: 79px !important;
      resize: vertical;
      margin-bottom: 24px;
      font-size: 13px;
    }

    &__desc {
      width: 100%;
      padding: 0 18px;
      margin-top: 8px;
      position: relative;
    }
    &__desc-title {
      position: absolute;
      z-index: @zindex-hack;
      top: 1px;
      left: 27px;
      width: calc(100% - 54px);
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      padding: 10px 10px 0 4px;
      box-sizing: border-box;
      background: #fff;
    }
    &__desc-input {
      font-size: 14px;
    }


    &__confirm {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__confirm-radio {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &__agree-warn {
      text-align: center;
      margin-bottom: 10px;
    }
    &__confirm-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border: 1.5px solid #BBBBBB;
      border-radius: 16px;
      margin-right: 8px;
      flex-shrink: 0;
    }
    &__confirm-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
      color: #767676;
      .policy {
        color: #2D68A8;
        text-decoration-line: underline;
      }
    }
  }
}
</style>
