<template>
  <s-popover
    v-model="showTips"
    theme="light"
    :placemen="position"
    :append-to-body="appendToBody"
    :hide-close-icon="true"
    :trigger="'unset'"
    class="comment-report__container"
  >
    <!-- Content -->
    <div
      class="tips-content"
      @click="reportClick"
    >
      {{ language.SHEIN_KEY_PC_21452 }}
    </div>
    <template #reference>
      <div
        class="more-btn"
        @click="updateTips"
      >
        <Icon
          name="sui_icon_more_24px"
          size="24px"
        />
      </div>
    </template>
  </s-popover>
</template>

<script>
import CommentReportPopover from './report'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'
daEventCenter.addSubscriber({ modulecode: '2-20' })
const { lang } = gbCommonInfo
export default {
  name: 'CommentReport',
  components: {
    Icon
  },
  props: {
    commentId: {
      type: String,
      default: ''
    },
    isOutStoreReview: {
      type: Boolean,
      default: false
    },
    skc: {
      type: String,
      default: ''
    },
    isFree: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'top-end'
    },
    analysis: {
      type: Object,
      default() {
        return {
          activity_from: '',
          location: ''
        }
      }
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: null
    },
    reportMemberId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      showTips: false,
      timer: null,
      language: {},
      options: []
    }
  },
  created() {
    this.fetchLanguage()
  },
  methods: {
    fetchLanguage() {
      const cacheLanguage = sessionStorage.getItem(`${lang}_COMMENT_REPORT_LANGUAGE`)
      if (cacheLanguage) {
        this.setData(JSON.parse(cacheLanguage))
      } else {
        schttp({
          url: '/api/common/language/get',
          params: { page: 'comment_report' }
        }).then(({ language }) => {
          this.setData(language)
          sessionStorage.setItem(
            `${lang}_COMMENT_REPORT_LANGUAGE`,
            JSON.stringify(language)
          )
        })
      }
    },
    setData(language) {
      this.language = language
      this.options = [
        language.SHEIN_KEY_PC_22442,
        language.SHEIN_KEY_PC_22444,
        language.SHEIN_KEY_PC_22446,
        language.SHEIN_KEY_PC_22447,
        language.SHEIN_KEY_PC_22443,
        language.SHEIN_KEY_PC_22445
      ]
    },
    reportClick() {
      const _this = this
      const { commentId = '', skc = '', isFree, options, language, isOutStoreReview, reportMemberId } = _this
      const props = { options, language }
      CommentReportPopover.open({
        props,
        config: {
          skc,
          isFree,
          comment_id: commentId,
          isOutStoreReview,
          callbacks: {
            submit: ({ comment_id, reason }) => {
              this.$emit('reportSubmit', { comment_id, reason })
              daEventCenter.triggerNotice({
                daId: '2-20-2',
                extraData: {
                  id: comment_id,
                  reason,
                  location: this.analysis.location
                }
              })
            },
            closeModal: this.closeModal,
          },
          reportMemberId
        }
      })
      this.$emit('reportClick', { comment_id: commentId })
      _this.showTips = false
      const extraData = {
        activity_from: this.analysis.activity_from,
      }
      if (this.analysis.activity_from === 'store_reviews') extraData.review_id = commentId
      daEventCenter.triggerNotice({
        daId: '2-20-1',
        extraData,
      })
    },
    updateTips() {
      this.showTips = true
      this.setTimer()
    },
    setTimer(holding) {
      this.timer && clearTimeout(this.timer)
      if (holding) return false
      this.timer = setTimeout(() => {
        this.showTips = false
      }, 3000)
    }
  },
  emits: ['reportSubmit', 'reportClick']
}
</script>

<style lang="less">
.comment-report__container {
  .tips-content,
  .more-btn {
    cursor: pointer;
  }
  .tips-content {
    white-space: nowrap;
  }
}
</style>
