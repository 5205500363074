<template>
  <div
    ref="emailRecommendInput"
    class="email-recommend-input"
  >
    <s-input 
      v-model.trim="inputValue"
      v-bind="$attrs"
      :clearable="true"
      :aria-label="ariaLabel"
      :placeholder="inputPlaceholder"
      @click="handleClick"
      @focus="handleFocus"
      @input="handleInput"
      @key-down-enter="handleKeyDownEnter"
    >
      {{ $slots.default }}
    </s-input>
    <ClientOnly>
      <template v-if="isLoadEmailList">
        <transition
          name="sui-animation__selectmenu"
        >
          <ul
            v-show="!!recommendEmailList.length"
            class="autocomplete-wrap"
          >
            <li
              v-for="(email, i) in recommendEmailList" 
              :key="`${email}__${i}`"
              @click="handleSelect(email)"
            >
              {{ email }}
            </li>
          </ul>
        </transition>
      </template>
    </ClientOnly>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SInput } from '@shein-aidc/sui-input'
import { ClientOnly } from '@sheinfe/vue-client-only'

const baseRecommendEmails = [
  'gmail.com',
  'hotmail.com',
  'yahoo.es',
  'hotmail.es',
  'yahoo.com',
  'telefonica.net',
  'outlook.com',
  'msn.com',
  'live.com',
  'icloud.com'
]
export default {
  name: 'EmailRecommendInput',
  components: {
    SInput,
    ClientOnly
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: '' 
    },
    forbiddenRecommend: {
      type: Boolean,
      default: false
    },
    inputPlaceholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputValue: this.value,
      show6: false,
      isFocus: false,
      isLoadEmailList: false
    }
  },
  computed: {
    recommendEmailList: {
      set () {},
      get () {
        const atReg = /(.*)@(.*)/
        const { isFocus, inputValue = '', forbiddenRecommend } = this
        if (!isFocus || !inputValue || !!forbiddenRecommend) return [] // 无内容不展示
        const matchAt = inputValue.match(atReg)
        if (!!baseRecommendEmails.find(v => v === matchAt?.[2])) return [] // 已属于推荐邮箱则不展示
        if (matchAt && !matchAt?.[1]) return [] // 以@开头不展示推荐
        return baseRecommendEmails.map(domain => {
          if (!matchAt) return `${inputValue}@${domain}` // 没有@则完整填充
          if (domain.indexOf(matchAt?.[2]) === 0) return `${matchAt[1]}@${domain}`
          return false
        }).filter(Boolean)
      }
    }
  },
  watch: {
    inputValue () {
      this.$emit('update:modelValue', this.inputValue)
    },
    recommendEmailList (nl, ol) {
      if (nl?.length && !ol?.length) {
        daEventCenter.triggerNotice({
          daId: '2-7-92'
        })
      }
    }
  },
  mounted () {
    document.body.addEventListener('click', this.handleBlurListner)
    setTimeout(() => {
      this.isLoadEmailList = true
    }, 200)
  },
  beforeUnmount () {
    document.body.removeEventListener('click', this.handleBlurListner)
  },
  methods: {
    // 触发模拟失焦事件，因为原生失焦事件在点击推荐邮箱就会触发
    handleBlurListner (e) {
      const target = e.target || null
      // 不在推荐输入框范围内 并且当前聚焦状态是true，模拟触发blur事件
      if (this.isFocus && !this.$refs.emailRecommendInput.contains(target)) {
        this.isFocus = false
        this.$emit('blur')
      }
    },
    handleClick () {
      this.$emit('click')
    },
    handleFocus () {
      this.isLoadEmailList = true
      this.isFocus = true
      this.$emit('focus')
    },
    handleInput (val) {
      this.isLoadEmailList = true
      this.$emit('update:modelValue', val)
    },
    handleSelect (email = '') {
      daEventCenter.triggerNotice({
        daId: '2-7-93',
        extraData: {
          suffix: email?.replace(/.*@/, '')
        }
      })
      if (email) {
        this.isFocus = false
        this.$emit('update:modelValue', email)
      }
    },
    handleKeyDownEnter () {
      this.$emit('key-down-enter')
    }
  },
  emits: ['key-down-enter', 'update:modelValue', 'blur', 'focus', 'click']
}
</script>

<style lang="less" scoped>
.email-recommend-input {
  position: relative;
  z-index: @zindex-dropdown;
  display: inline-block;
  width: 100%;
  .autocomplete-wrap {
    list-style: none;
    max-height: 240px;
    z-index: @zindex-dropdown + 1;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    margin-top: 4px;
    padding: 8px 0;
    box-shadow: 0px 1px 4px 3px rgba(0,0,0,0.08);
    background-color: @sui_color_white;
    overflow: auto;
    font-size: 13px;
    color: @sui_color_gray_dark1;
    li {
      list-style: none;
      .flexbox();
      .space-between();
      .align-center();
      cursor: pointer;
      background-color: @sui_color_white;
      padding: 12px 16px;
      &:hover {
        background-color: @sui_color_gray_weak2;
      }
    }
  }
}
</style>
